import Desc from "../../components/Desc/Desc"
import Team from "../../components/Team/Team"
import Goals from "../../components/Goals/Goals"

const About = () => {
	return (
		<>
			<Desc />
			<Team />
			<Goals />
		</>
	)
}

export default About