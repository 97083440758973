import { Swiper, SwiperSlide } from 'swiper/react';
import { TwitterShareButton } from 'react-share';
import { TwitterIcon } from 'react-share';
import { Navigation } from 'swiper/modules';
import './Single.scss'
import h1 from '../../img/single/1.png'
import h2 from '../../img/single/2.png'
import h3 from '../../img/single/3.png'
import h4 from '../../img/single/4.png'
import h5 from '../../img/single/5.png'
import h6 from '../../img/single/6.png'
import h7 from '../../img/single/7.png'
import h8 from '../../img/single/8.png'
import h9 from '../../img/single/9.png'
import h10 from '../../img/single/10.png'
import h11 from '../../img/single/11.png'
import h12 from '../../img/single/12.png'
import h13 from '../../img/single/13.png'
import h14 from '../../img/single/14.png'
import h15 from '../../img/single/15.png'
import h16 from '../../img/single/16.png'
import h17 from '../../img/single/17.png'
import h18 from '../../img/single/18.png'
import h19 from '../../img/single/19.png'
import h20 from '../../img/single/20.png'
import hand from '../../img/global/hand.png'

const Single = () => {
	const shareUrl = window.location.href

    return (
        <section className="single">
			<div className="container">
				<h1>
					Crypto Hamsters
				</h1>
				<div className="single-wrapper">
					<p>
						Our first hand-drawn collection. It took about 3 months to create.
					</p>
					<Swiper spaceBetween={50} slidesPerView={1} modules={[Navigation]} navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
					breakpoints={{
						320: {
						slidesPerView: 1,
						spaceBetween: 10,
						},
						480: {
						slidesPerView: 2,
						spaceBetween: 20,
						},
						768: {
						slidesPerView: 3,
						spaceBetween: 25,
						},
						1200: {
							slidesPerView: 3,
							spaceBetween: 50,
						}
					}}
					>
						<SwiperSlide>
							<img src={h1} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h2} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h3} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h4} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h5} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h6} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h7} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h8} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h9} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h10} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h11} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h12} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h13} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h14} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h15} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h16} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h17} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h18} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h19} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h20} alt="hamster" />
						</SwiperSlide>
						<div className="swiper-buttons">
							<button className="swiper-button-prev">
								<img src={hand} alt="prev" />
							</button>
							<button className="swiper-button-next">
								<img src={hand} alt="prev" />
							</button>
						</div>
						
					</Swiper>
					<div className="single-links">
						<a className='read-more br' target='_blank' rel='noreferrer' href="https://www.stargaze.zone/l/cryptohamsters">
							Go to market
						</a>
							<TwitterShareButton className='share' url={shareUrl} title="Crypto Hamsters. Our first hand-drawn collection. It took about 3 months to create." hashtags={["#cryptomatcha", "#nft", "#cryptohamsters", "#afterthehalving"]}>
								<TwitterIcon borderRadius={0} bgStyle={{fill: "#111"}} size={50} />
							</TwitterShareButton>
					</div>
				</div>
			</div>
        </section>
    );
}

export default Single;
