import React, { useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu';
import Top from './components/Top/Top';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Collections from './pages/Collections/Collections';
import Partnerships from './pages/Partnerships/Partnerships';
import News from './pages/News/News';
import Apps from './pages/Apps/Apps';
import Single from './pages/Single/Single';
import SingleAh from './pages/Single/SingleAh';
import MatchaLoader from './components/Loader/MatchaLoader';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <main>
          {loading ? (
            <MatchaLoader />
          ) : (
            <div className='block'>
              <div className="panel">
                <Menu />
                <Top />
              </div>
              <div className="block-inner">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/collections" element={<Collections />} />
                  <Route path="/partnerships" element={<Partnerships />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/apps" element={<Apps />} />
                  <Route path="/single/crypto-hamsters" element={<Single />} />
                  <Route path="/single/after-the-halving" element={<SingleAh />} />
                </Routes>
                <ScrollToTop />
              </div>
            </div>
          )}
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
