import Hero from "../../components/Hero/Hero"
import Info from "../../components/Info/Info"
import infoData from '../../data/infoData.json'
import hamsterImg from '../../components/Top/ham.jpg'
import afthImg from '../../img/info/afth.jpeg'

const Home = () => {
	return (
		<>
			<Hero />
			<Info type={''} state={'soon'} title={'Mint NOW'} data={infoData.hamster.hamsterText} img={hamsterImg} link="/single/crypto-hamsters" link2="https://www.stargaze.zone/l/cryptohamsters"/>
			<Info type={'reverse'} state={''} title={'Our classic'} data={infoData.afth.text} img={afthImg} link="/single/after-the-halving" link2="/single/after-the-halving"/>
		</>
	)
}

export default Home