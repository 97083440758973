import './Hero.scss';
import cat from '../../img/cats/cat3.svg';
import soundSrc from './urr.mp3';
import { useRef, useEffect } from 'react';

const Hero = () => {
    const audioRef = useRef(null);
    const titleRef = useRef(null);

    const handleImageClick = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const title = "CRYPTOMATCHA";
    const letters = title.split('');

    useEffect(() => {
        const animateTitle = () => {
            if (titleRef.current) {
                titleRef.current.classList.add('animate');
                setTimeout(() => {
                    if (titleRef.current) {
                        titleRef.current.classList.remove('animate');
                    }
                }, 3000);
            }
        };

        animateTitle();
        const intervalId = setInterval(animateTitle, 5000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section className='hero'>
            <div className="container">
                <div className="hero-info">
                    <h1 ref={titleRef}>
                        {letters.map((letter, index) => (
                            <span key={index} className="wave">{letter}</span>
                        ))}
                        <img onClick={handleImageClick} className='cat' src={cat} alt="Cat Icon" />
                        <audio ref={audioRef} src={soundSrc} />
                    </h1>
                    <p>
                        Explore, create, own — together with us in the world of crypto and NFTs
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Hero;
