import './Partnership.scss'

const Partnership = ({title, img, text, collection, twitter, discord, token, website, mod, shareTitle}) => {

    return (
        <section className={`partnership ${mod}`}>
			<div className="partnership-wrapper">
				<h4>
					{title}    
				</h4>
				<div className="partnership-inner">
					<div className="partnership-img">
						<img src={img} alt="" />
					</div>
					<div className="partnership-info">
						<p>
						{text}
						</p>
						<div className="partnership-links">
						{collection && (
							<a className='read-more' href={collection} target='_blank' rel='noreferrer'>
								Collection
							</a>
						)}
						{twitter && (
							<a className='read-more' href={twitter} target='_blank' rel="noreferrer">
								Twitter
							</a>
						)}
						{discord && (
							<a className='read-more' href={discord} target='_blank' rel="noreferrer">
								Discord
							</a>
						)}
						{token && (
							<a className='read-more' href={token} target='_blank' rel="noreferrer">
								Token
							</a>
						)}
						{website && (
							<a className='read-more' href={website} target='_blank' rel="noreferrer">
								Website
							</a>
						)}
						</div>
					</div>
				</div>
			</div>
        </section>
    )
}

export default Partnership
