import { NavLink } from 'react-router-dom';
import './Footer.scss'
import logo from '../../img/global/logo.svg'
import x from '../../img/socials/x.svg'
import discord from '../../img/socials/discord.svg'

const Footer = () => {
	return (
		<footer className='footer'>
			<div className="container">
				<div className="footer-wrapper">
					<NavLink to="/" className="footer-logo">
						<img src={logo} alt="" />
					</NavLink>
					<nav>
						<ul>
							<li>
								<NavLink to="/about">
									About us
								</NavLink>
							</li>
							<li>
								<NavLink to="/collections">
									Collections
								</NavLink>
							</li>
							<li>
								<NavLink to="/partnerships">
									Partnerships
								</NavLink>
							</li>
							<li>
								<NavLink to="/news">
									News
								</NavLink>
							</li>
							<li className='disabled'>
								<NavLink to="apps">
									Apps
								</NavLink>
							</li>
						</ul>
					</nav>
					<div className="footer-socials">
						<a href="https://x.com/crypmatcha" target='_blank' className="social-link lg" rel="noreferrer">
							<img src={x} alt="" />
						</a>
						<a href="https://discord.com/invite/SPVpHhBV29" target='_blank' className="social-link lg" rel="noreferrer">
							<img src={discord} alt="" />
						</a>
					</div>
				</div>
				<div className="footer-info">
					<p>Cryptomatcha</p>
					<span>2024</span>
				</div>
			</div>
		</footer>
	)
}

export default Footer