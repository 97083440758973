import './Collections.scss'
import Collection from '../../components/Collection/Collection'

const Collections = () => {
	return (
		<section className='collections'>
			<div className="container">
				<h1>
					Collections
				</h1>
				<div className="collections-wrapper">
					<Collection collectionAddr={'stars1u478mmpm4mv33dkhvt6eryrru3uer6lr5pp8vgt7mhyvxjekx9js9y3kvl'} link="/single/after-the-halving" />
					<Collection collectionAddr={'stars1k7lmdfx2eh5k3dt4jz3uuv4wl6s0tyft2twwjy0mgs3qxs3u3ynssv8dr2'} link="/single/crypto-hamsters" />
				</div>
			</div>
		</section>
	)
}

export default Collections