import './Header.scss'
import x from '../../img/socials/x.svg'
import discord from '../../img/socials/discord.svg'
import logo from '../../img/global/logo.svg'
import catmenu from '../../img/global/catmenu.png'
import { NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react'

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const closeMenu = () => {
		setIsMenuOpen(false)
	}

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 992 && isMenuOpen) {
				setIsMenuOpen(false)
			}
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [isMenuOpen])

	return (
		<header className={`header ${isMenuOpen ? 'opened' : ''}`}>
			<div className="container">
				<NavLink to="/" className='header-logo-mob'>
					<img src={logo} alt="Logotype with cats" />
					</NavLink>
				<div className="header-wrapper">
					<ul>
						<li>
							<a href="https://x.com/crypmatcha" className='social-link' target='_blank' rel="noreferrer">
								<img src={x} alt="Twitter icon" />
							</a>
						</li>
						<li>
							<a href="https://discord.com/invite/SPVpHhBV29" className='social-link' target='_blank' rel="noreferrer">
								<img src={discord} alt="Twitter icon" />
							</a>
						</li>
					</ul>
					<ul className='mobile-menu'>
						<li>
							<NavLink to="/" onClick={closeMenu}>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink to="/about" onClick={closeMenu}>
								About Us
							</NavLink>
						</li>
						<li>
							<NavLink to="/collections" onClick={closeMenu}>
								Collections
							</NavLink>
						</li>
						<li>
							<NavLink to="/partnerships" onClick={closeMenu}>
								Partnerships
							</NavLink>
						</li>
						<li>
							<NavLink to="/news" onClick={closeMenu}>
								News
							</NavLink>
						</li>
						<li className='disabled'>
							<NavLink to="/apps" onClick={closeMenu}>
								Apps
							</NavLink>
						</li>
					</ul>
					<button className='button wallet'>
						Connect Wallet
					</button>
				</div>
				<button className='burger-button' onClick={toggleMenu}>
					<img src={catmenu} alt="Menu" />
				</button>
			</div>
		</header>
	)
}

export default Header
