import './Info.scss'
import cat from '../../img/cats/cat1.svg'
import catReverse from '../../img/cats/cat4.svg'
import { Link } from 'react-router-dom'

const Info = ({type, state, title, data, img, link, link2}) => {
	return (
		<section className={`info ${type} ${state}`}>
			<img className='cat' src={type === 'reverse' ? catReverse : cat} alt="" />
			<div className="container">
				<h2><a href={link2} target='_blank' rel='noreferrer'>{title}</a></h2>
				<div className="info-item">
					<div className="info-img">
						<img src={img} alt="" />
					</div>
					<div className="info-text">
						<p>
							{data}
						</p>
					</div>
				</div>
				<div className="button-wrapper end">
					<Link to={link} className='read-more' rel="noreferrer">
						Read More
					</Link>
				</div>
			</div>
		</section>
	)
}

export default Info