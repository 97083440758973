import './Partnerships.scss'
import Partnership from '../../components/Partnership/Partnership'
import shitmos from '../../img/utils/shitmos.jpg'
import afth from '../../img/utils/smok.jpeg'

const Partnerships = () => {
	return (
		<section className='partnerships'>
			<div className="container">
				<h1>
					Partnerships
				</h1>
				<div className="partnerships-wrapper">
					<Partnership title="Shitmos" img={shitmos}
						text="Shitmos Economic Zone - we are the one of first collections who joined SEZ. 20% of our royalties 
						goes to SEZ treasury for buybacks of Shitmos - a real community moving memecoin in whole Cosmos
						Community is very strong and grows every day." collection="https://www.stargaze.zone/m/shitmos/tokens"
						twitter="https://x.com/ShitonCosmos" token="https://coinhall.org/osmosis/osmo16vwmg3xmlzltk24upzx9nd957sl0ffqnc0vc5ld0gzqtz3g7yplqeu8nrk" mod="shitmos" website="https://shitmos.wtf/" />
						<Partnership title="Smoker Club" img={afth}
						text="Smoker Club - we have 3 traits in collection which gives you membership in Smokers Club - 
						place where you can join rumbles and other games and win nice NFTs.
						Also you can soft-stake smoker NFTs on plstacking to earn SMKR token and join raffles there. We burned 10% of supply. Floor price is stable x3 - x4 from mint. "discord="https://discord.gg/KS6skkjYDR"
						twitter="https://x.com/Smokers_NFTs" />
				</div>
			</div>
		</section>
	)
}

export default Partnerships