import './Desc.scss'
import matcha from '../../img/global/matcha.svg'

const Desc = () => {
	return (
		<section className='description'>
			<div className="container">
			<h1>About Us</h1>
				<div className="description-wrapper">
					<div className="description-inner">
						<div className="description-info">
							<p>
								In these times, when the world of digital assets and technology is evolving at a surprisingly fast pace, young and ambitious teams play a key role in shaping this trend.
							</p>
							<p>
								Our team is a prime example of such a dynamic group of people who are committed to creating amazing and innovative solutions in the world of NFT and development. We embody enthusiasm, energy and a drive to explore new paths and opportunities.
							</p>
							<p>
								In our team, everyone has the opportunity to express their ideas, contribute and grow professionally. We support openness, tolerance and mutual respect, creating an atmosphere in which each team member feels respected and an important link in the common cause.
							</p>
						</div>
						<div className="description-img">
							<img src={matcha} alt="Cup Of Matcha" />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Desc