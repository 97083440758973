import cat1 from '../../img/cats/cat1.svg'
import cat2 from '../../img/cats/cat2.svg'
import cat3 from '../../img/cats/cat3.svg'
import cat4 from '../../img/cats/cat4.svg'
import './Loader.scss'

const images = [cat1, cat2, cat3, cat4]

const Loader = () => {
    const randomIndex = Math.floor(Math.random() * images.length)
    const randomImage = images[randomIndex]

    return (
        <div className="loader">
            <img src={randomImage} alt="" />
        </div>
    )
}

export default Loader