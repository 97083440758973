import './Top.scss'
import hamster from '../../img/news/ham.jpg'
import tower from '../../img/news/tower.gif'
import { Link } from 'react-router-dom'

const Top = () => {
	return (
		<div className="top">
			<div className="top-wrapper">
				<Link to="/news#hamster-news" className="top-item">
					<div className="top-item-img">
						<img src={hamster} alt="hamsters highlight" />
					</div>
					<div className="top-item-inner">
						
						<div className="top-item-desc">
							Crypto Hamsters collection on @StargazeZone
						</div>
					</div>
				</Link>
				<Link to="/news#tower-news" className="top-item">
					<div className="top-item-img">
						<img src={tower} alt="proto towers highlight" />
					</div>
					<div className="top-item-inner">
						<div className="top-item-desc">
						Completed a tower project for @crypmatcha and @flarnrules1.
						Waiting to publish to Proto Towers. After, will share the full work in .gif
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}

export default Top