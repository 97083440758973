import './Team.scss'
import m1 from '../../img/team/member1.jpeg'
import m2 from '../../img/team/member2.jpeg'
import m3 from '../../img/team/member3.jpeg'

const Team = () => {
	return (
		<section className="team">
			<div className="container">
				<h2>Our team</h2>
				<div className="team-wrapper">
					<div className="team-info">
						<p>
							Our team are professionals with different talents, united by a common goal: to create cool projects. We don't just work together - we inspire each other by sharing ideas and experience.
						</p>
					</div>
					<div className="team-members">
						<div className="team-member">
							<div className="team-member-img">
								<img src={m1} alt="" />
							</div>
							<div className="team-member-position">
								Art designer
							</div>
							<p>
								From the idea in our heads to the finished traits, the designer's task is to draw every pixel perfectly, to put it together, to combine it into a finished image. All the art we see in the end is her handiwork.
							</p>
							<div className="team-member-socials">
								<a href="https://x.com/d_art90" target='_blank' rel="noreferrer">Twitter</a>
								<a href="/" className='disabled' target='_blank' rel="noreferrer">Discord</a>
							</div>
						</div>
						<div className="team-member">
							<div className="team-member-img">
								<img src={m2} alt="" />
							</div>
							<div className="team-member-position">
								Matcha man
							</div>
							<p>
								A new collaboration? A name for the collection? Giveaway? Matcha man should organize everything into a single working mechanism and share with his community information about everything. Cooperation between designer and web developer? Easy peasy
							</p>
							<div className="team-member-socials">
								<a href="https://x.com/crypmatcha" target='_blank' rel="noreferrer">Twitter</a>
								<a href="https://discord.com/invite/SPVpHhBV29" target='_blank' rel="noreferrer">Discord</a>
							</div>
						</div>
						<div className="team-member">
							<div className="team-member-img">
								<img src={m3} alt="" />
							</div>
							<div className="team-member-position">
								Web developer
							</div>
							<p>
								Need to spread revenues to all holders? This is where web developer comes into play. Smart contracts, backend, frontend, everything where program code is present - for him it is a common thing!
							</p>
							<div className="team-member-socials">
								<a href="/" className='disabled'  target='_blank' rel="noreferrer">Twitter</a>
								<a href="/" className='disabled' target='_blank' rel="noreferrer">Discord</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Team