import { NavLink } from 'react-router-dom'
import './Menu.scss'
import logo from '../../img/global/logo.svg'

const Menu = () => {
	return (
		<div className="menu">
			<nav>
				<ul>
                    <li>
						<NavLink to="/"  className='menu-link'>
							<img src={logo} alt="Logotype with cats" />
						</NavLink>
					</li>
					<li>
						<NavLink to="/about">
							About Us
						</NavLink>
					</li>
					<li>
						<NavLink to="/collections">
							Collections
						</NavLink>
					</li>
					<li>
						<NavLink to="/partnerships">
							Partnerships
						</NavLink>
					</li>
					<li>
						<NavLink to="/news" >
							News
						</NavLink>
					</li>
					<li className='disabled'>
						<NavLink to="/locked">
							Apps
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default Menu