import { Swiper, SwiperSlide } from 'swiper/react'
import { TwitterShareButton } from 'react-share';
import { TwitterIcon } from 'react-share';
import { Navigation } from 'swiper/modules'
import './Single.scss'
import h1 from '../../img/single/v1.jpg'
import h2 from '../../img/single/v2.jpg'
import h3 from '../../img/single/v3.jpg'
import h5 from '../../img/single/v5.jpg'
import h6 from '../../img/single/v6.jpg'
import h7 from '../../img/single/v7.jpg'
import h8 from '../../img/single/v8.jpg'
import h9 from '../../img/single/v9.jpg'
import h10 from '../../img/single/v10.jpg'
import h11 from '../../img/single/v11.jpg'
import h12 from '../../img/single/v12.jpg'
import h13 from '../../img/single/v13.jpg'
import h14 from '../../img/single/v14.jpg'
import h15 from '../../img/single/v15.jpg'
import h16 from '../../img/single/v16.jpg'
import h17 from '../../img/single/v17.jpg'
import h18 from '../../img/single/v18.jpg'
import h19 from '../../img/single/v19.jpg'
import h20 from '../../img/single/v20.jpg'
import hand from '../../img/global/hand.png'

const SingleAH = () => {
	const shareUrl = window.location.href

    return (
        <section className="single">
			<div className="container">
				<h1>
					After The Halving
				</h1>
				<div className="single-wrapper">
					<p>
						Our first collection on Stargaze created with AI. At this point, the floor is kept around x3-x4. The collection minted in 17 hours. It's unrivaled results with absolutely no marketing whatsoever. It's our classic.
					</p>
					<Swiper spaceBetween={50} slidesPerView={1} modules={[Navigation]} navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
					breakpoints={{
						320: {
						slidesPerView: 1,
						spaceBetween: 10,
						},
						480: {
						slidesPerView: 2,
						spaceBetween: 20,
						},
						768: {
						slidesPerView: 3,
						spaceBetween: 50,
						},
						1200: {
							slidesPerView: 3,
							spaceBetween: 50,
						}
					}}
					>
						<SwiperSlide>
							<img src={h1} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h2} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h3} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h5} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h6} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h7} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h8} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h9} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h10} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h11} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h12} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h13} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h14} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h15} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h16} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h17} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h18} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h19} alt="hamster" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={h20} alt="hamster" />
						</SwiperSlide>
						<div className="swiper-buttons">
							<button className="swiper-button-prev">
								<img src={hand} alt="prev" />
							</button>
							<button className="swiper-button-next">
								<img src={hand} alt="prev" />
							</button>
						</div>
						
					</Swiper>
					<div className="single-links">
						<a className='read-more br' target='_blank' rel='noreferrer' href="https://www.stargaze.zone/m/after-halving/tokens">
							Go to market
						</a>
							<TwitterShareButton className='share' url={shareUrl} title="Our first collection on Stargaze created with AI. At this point, the floor is kept around x3-x4. The collection minted in 17 hours. It's unrivaled results with no marketing whatsoever. It's our classic." hashtags={["#nft", "#afterthehalving"]}>
								<TwitterIcon borderRadius={0} bgStyle={{fill: "#111"}} size={50} />
							</TwitterShareButton>
					</div>
				</div>
			</div>
        </section>
    )
}

export default SingleAH
