import React, { useState, useEffect } from 'react'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import './Collection.scss'
import Loader from '../Loader/Loader'
import star from '../../img/global/star.svg'
import { Link } from 'react-router-dom'

const client = new ApolloClient({
    uri: 'https://graphql.mainnet.stargaze-apis.com/graphql',
    cache: new InMemoryCache(),
})

const getCollectionData = (collectionAddr) =>
    client.query({
        query: gql`
            query CollectionData($address: String!) {
                collection(address: $address) {
                    name
                    description
                    floorPrice
                    media {
                        visualAssets {
                            lg {
                                staticUrl
                            }
                        }
                    }
                    highestOffer {
                        offerPrice {
                            amount
                        }
                    }
                    tokenCounts {
                        total
                        burned
                    }
                    royaltyInfo {
                        sharePercent
                    }
                    contractUri
                }
            }
        `,
        variables: { address: collectionAddr },
    })

const Collection = ({ collectionAddr, link }) => {
    const [collectionData, setCollectionData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!collectionAddr) {
                    console.error('Collection address is missing')
                    setError('Collection address is missing')
                    return
                }

                const { data } = await getCollectionData(collectionAddr)
                setCollectionData(data.collection)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
                setError('Error fetching data. Please try again later.')
                setLoading(false)
            }
        }

        fetchData()
    }, [collectionAddr])

    return (
        <div className="collection">
            {loading ? (
                <Loader />
            ) : collectionData ? (
                <>
                    <div className="collection-inner">
                        <div className="collection-img">
                            <img src={collectionData.media.visualAssets.lg.staticUrl} alt={collectionData.name}/>
                        </div>
                        <div className="collection-info">
                            <h4>{collectionData.name}</h4>
                            <div className="collection-stats">
                                {collectionData.floorPrice && (
                                    <div className="collection-price">
                                        Floor: <span>{collectionData.floorPrice / 1000000} <img src={star} width="30" height="30" alt="stars" /></span>
                                    </div>
                                )}
                                {collectionData.highestOffer && (
                                    <div className="highest-offer">
                                        Highest Offer: <span>{collectionData.highestOffer.offerPrice.amount / 1000000} <img src={star} width="30" height="30" alt="stars"/></span>
                                    </div>
                                )}
                                {collectionData.royaltyInfo && (
                                    <div className="royalty-info">
                                        Royalty: <span>{collectionData.royaltyInfo.sharePercent.toFixed(1)}%</span>
                                    </div>
                                )}
                                {collectionData.tokenCounts && (
                                    <>
                                        <div className="unique-owners">
                                            Total Tokens: <span>{collectionData.tokenCounts.total - collectionData.tokenCounts.burned}</span>
                                        </div>
                                        <div className="burned-tokens">
                                            Burned NFT's: <span>{collectionData.tokenCounts.burned}</span>
                                        </div>
                                    </>
                                )}
                            </div>
                            {collectionData.description && (
                                <p>{collectionData.description}</p>
                            )}
							<div className="collection-links">
								{collectionData.contractUri && (
									<div className="collection-link">
										<a className='read-more' href={`https://www.stargaze.zone/m/${collectionData.contractUri}`}>View on Stargaze</a>
									</div>
								)}
								{link && (
									<div className="collection-link">
										<Link to={link} className='read-more'>View more</Link>
									</div>
								)}
								</div>
							</div>
                           
                    </div>
                </>
            ) : (
                <div className="error-message">{error}</div>
            )}
        </div>
    )
}

export default Collection
