import './Loader.scss'
import logo1 from '../../img/global/logo1.svg'
import logo2 from '../../img/global/logo2.svg'


const MatchaLoader = () => {
	return (
		<div className="matcha-loader">
			<div className="matcha-loader-inner">
				<img className='logo1' src={logo1} alt="" />
				<img className='logo2' src={logo2} alt="" />
			</div>
		</div>
	)
}

export default MatchaLoader