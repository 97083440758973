import './Goals.scss'

const Goals = () => {
	return (
		<section className='goals'>
			<div className="container">
				<h2>Goals</h2>
				<div className="goals-wrapper">
					<div className="goals-info">
						<p>
							Our goals is a thoughtfully planned path that we intend to follow to achieve our ambitious goals. Here are some of the key points of our journey. We are committed to making meaningful contributions to the NFT space.
						</p>
					</div>
					<ul className="goals-list">
						<li>
							<span>Stacking</span>
							Stacking STARS to improve network security and value.
						</li>
						<li>
						<span>Collaborations</span>
							Attracting and collaborations with communities from other networks to join Stargaze.
						</li>
						<li>
						<span>Bonuses</span>
							Bonuses for specific traits and for holders of our collections in total.
						</li>
						<li>
						<span>Sweeping</span>
							Floor sweeping and burning to increase value of our collections.
						</li>
						<li>
							<span>Creating</span>
							Creating cool art and doing something you love.
						</li>
						<li>
						<span>Development</span>
							Web3 development. Creating app's in connection with Stargaze.
						</li>
						<li>
						<span>Attracting</span>
							Attracting people from other fields to web3.
						</li>
						<li>
						<span>Community</span>
							Being a part of best NFT community - Stargaze.
						</li>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default Goals