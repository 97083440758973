import './News.scss'
import tower from '../../img/news/tower.gif'
import hamster from '../../img/news/ham.jpg'
import { Link } from 'react-router-dom'

const News = () => {
	return (
		<section className='news'>
			<div className="container">
				<h1>
					News
				</h1>
				<div className="news-wrapper">
				<div className="news-item" id='hamster-news'>
						<div className="news-item-inner">
							<div className="news-item-img">
								<img src={hamster} alt="matcha tower" />
								<Link to="/single/crypto-hamsters" className='read-more' href="/">
									View
								</Link>
							</div>
							<div className="news-item-info">
								<h4>Crypto Hamsters</h4>
								<p>
									Welcome to the world of Crypto Hamsters! This unique NFT collection was created by us long before hamsters became a trend. We started crafting it about three months ago. With over a hundred unique traits, you'll see it all for yourself! <br /><br />

									Mint begins on June 13, 2024, at 19:00 UTC
								</p>
							</div>
						</div>
					</div>
					<div className="news-item" id='tower-news'>
						<div className="news-item-inner">
							<div className="news-item-img">
								<img src={tower} alt="matcha tower" />
								<a href="https://www.stargaze.zone/m/proto-towers/tokens" target='_blank' rel='noreferrer' className='read-more'>
									View
								</a>
							</div>
							<div className="news-item-info">
								<h4>Proto Towers collaboration</h4>
								<p>
									Our close collaboration with Flarn, SEZ and Proto Towers has given us the opportunity to add some of their art to this collection. This will be a rare tower with smoker trait, of which there are only 4 out of 372. Made in Asperite, made with animation and love to create. We hope it will become a little gem of the Proto Towers collection.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default News
